import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Removes mux-player element from DOM when it's not visible.
 * Must be used on mux-player wrapper element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <div data-controller="mux-player-garbage-collector">
 *   <mux-player />
 * </div>
 */

export default class extends Controller {
  connect() {
    this.muxPlayerHTML = this.element.innerHTML

    this.observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].target.firstElementChild &&
          entries[0].target.firstElementChild.readyState > 0
        )
          return

        if (entries[0].isIntersecting) {
          if (entries[0].target.firstElementChild) return

          entries[0].target.innerHTML = this.muxPlayerHTML
        } else {
          entries[0].target.firstElementChild?.remove()
        }
      },
      {
        rootMargin: '1500px 0px 1500px 0px',
      },
    )

    this.observer.observe(this.element)
  }
}
